var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('b-card',{attrs:{"title":"New company"}},[_c('validation-observer',{ref:"companyValidation"},[_c('b-form',[_c('b-tabs',[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.tabErrors.details > 0)?_c('b-badge',{staticClass:"mr-1",attrs:{"pill":"","variant":"danger"}},[_vm._v(_vm._s(_vm.tabErrors.details)+" ")]):_vm._e(),_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"InfoIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Details")])]},proxy:true}])},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Name","label-for":"companyName"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"companyName","name":"companyName","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.formData.companyName),callback:function ($$v) {_vm.$set(_vm.formData, "companyName", $$v)},expression:"formData.companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Registry number","label-for":"registry-number"}},[_c('validation-provider',{attrs:{"name":"Registry number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"registry-number","name":"registry-number","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.formData.registryNumber),callback:function ($$v) {_vm.$set(_vm.formData, "registryNumber", $$v)},expression:"formData.registryNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"EORI number","label-for":"eoriNumber"}},[_c('validation-provider',{attrs:{"name":"EORI number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"eoriNumber","name":"eoriNumber","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.formData.eoriNumber),callback:function ($$v) {_vm.$set(_vm.formData, "eoriNumber", $$v)},expression:"formData.eoriNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"VAT number","label-for":"vatNumber"}},[_c('validation-provider',{attrs:{"name":"VAT number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vatNumber","name":"vatNumber","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.formData.vatNumber),callback:function ($$v) {_vm.$set(_vm.formData, "vatNumber", $$v)},expression:"formData.vatNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Fiscal reference","label-for":"fiscalReference"}},[_c('validation-provider',{attrs:{"name":"Fiscal reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fiscalReference","name":"fiscalReference","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.formData.fiscalReference),callback:function ($$v) {_vm.$set(_vm.formData, "fiscalReference", $$v)},expression:"formData.fiscalReference"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"E-mail","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"E-mail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"email","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","name":"phone","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.tabErrors.address > 0)?_c('b-badge',{staticClass:"mr-1",attrs:{"pill":"","variant":"danger"}},[_vm._v(_vm._s(_vm.tabErrors.address)+" ")]):_vm._e(),_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"HomeIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Address")])]},proxy:true}])},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Street and number","label-for":"street-number"}},[_c('validation-provider',{attrs:{"name":"Street and number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"street-number","name":"street-number","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.formData.streetNumber),callback:function ($$v) {_vm.$set(_vm.formData, "streetNumber", $$v)},expression:"formData.streetNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Postal code","label-for":"postalCode"}},[_c('validation-provider',{attrs:{"name":"Postal code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postalCode","name":"postalCode","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.formData.postalCode),callback:function ($$v) {_vm.$set(_vm.formData, "postalCode", $$v)},expression:"formData.postalCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"City","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","name":"city","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.formData.city),callback:function ($$v) {_vm.$set(_vm.formData, "city", $$v)},expression:"formData.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Region","label-for":"region"}},[_c('validation-provider',{attrs:{"name":"Region"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"region","name":"region","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.formData.region),callback:function ($$v) {_vm.$set(_vm.formData, "region", $$v)},expression:"formData.region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Country","label-for":"country"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"country","name":"country","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"type":"button","variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }