<template>
  <b-row>
    <b-col>
      <b-card title="New company">
        <validation-observer ref="companyValidation">
          <b-form>
            <b-tabs>

              <!-- Tab: Basic info -->
              <b-tab active>
                <template #title>
                  <b-badge
                    v-if="tabErrors.details > 0"
                    class="mr-1"
                    pill
                    variant="danger"
                  >{{ tabErrors.details }}
                  </b-badge>
                  <feather-icon
                    icon="InfoIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Details</span>
                </template>

                <b-row>
                  <b-col>
                    <b-form-group
                      label="Name"
                      label-for="companyName"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <b-form-input
                          id="companyName"
                          v-model="formData.companyName"
                          name="companyName"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group
                      label="Registry number"
                      label-for="registry-number"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Registry number"
                        rules="required"
                      >
                        <b-form-input
                          id="registry-number"
                          v-model="formData.registryNumber"
                          name="registry-number"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group
                      label="EORI number"
                      label-for="eoriNumber"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="EORI number"
                        rules="required"
                      >
                        <b-form-input
                          id="eoriNumber"
                          v-model="formData.eoriNumber"
                          name="eoriNumber"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="VAT number"
                      label-for="vatNumber"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="VAT number"
                      >
                        <b-form-input
                          id="vatNumber"
                          v-model="formData.vatNumber"
                          name="vatNumber"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Fiscal reference"
                      label-for="fiscalReference"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Fiscal reference"
                      >
                        <b-form-input
                          id="fiscalReference"
                          v-model="formData.fiscalReference"
                          name="fiscalReference"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="E-mail"
                      label-for="email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="E-mail"
                        rules="required"
                      >
                        <b-form-input
                          id="email"
                          v-model="formData.email"
                          name="email"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Phone"
                      label-for="phone"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Phone"
                      >
                        <b-form-input
                          id="phone"
                          v-model="formData.phone"
                          name="phone"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>

              <!-- Tab: Address -->
              <b-tab>
                <template #title>
                  <b-badge
                    v-if="tabErrors.address > 0"
                    class="mr-1"
                    pill
                    variant="danger"
                  >{{ tabErrors.address }}
                  </b-badge>
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Address</span>
                </template>
                <!-- Address form -->

                <b-row>
                  <b-col>
                    <b-form-group
                      label="Street and number"
                      label-for="street-number"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Street and number"
                        rules="required"
                      >
                        <b-form-input
                          id="street-number"
                          v-model="formData.streetNumber"
                          name="street-number"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group
                      label="Postal code"
                      label-for="postalCode"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Postal code"
                        rules="required"
                      >
                        <b-form-input
                          id="postalCode"
                          v-model="formData.postalCode"
                          name="postalCode"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group
                      label="City"
                      label-for="city"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="City"
                        rules="required"
                      >
                        <b-form-input
                          id="city"
                          v-model="formData.city"
                          name="city"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group
                      label="Region"
                      label-for="region"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Region"
                      >
                        <b-form-input
                          id="region"
                          v-model="formData.region"
                          name="region"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Country"
                      label-for="country"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Country"
                        rules="required"
                      >
                        <b-form-input
                          id="country"
                          v-model="formData.country"
                          name="country"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
            <b-row>
              <b-col>
                <b-button
                  type="button"
                  variant="primary"
                  @click="validationForm"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BTabs,
  BTab,
  BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    BBadge,
  },
  data() {
    return {
      formData: {
        companyName: '',
        registryNumber: '',
        eoriNumber: '',
        vatNumber: '',
        fiscalReference: '',
        streetNumber: '',
        postalCode: '',
        city: '',
        region: '',
        country: '',
        email: '',
        phone: '',
      },
      inputFieldMapping: {
        details: ['Name', 'Registry number', 'EORI number', 'VAT number', 'Fiscal reference', 'E-mail', 'Phone'],
        address: ['Postal code', 'City', 'Country', 'Region', 'Street and number'],
      },
      tabErrors: {
        details: 0,
        address: 0,
      },
    }
  },
  methods: {
    validationForm() {
      // Handle Tab validation
      this.$data.tabErrors = {
        details: 0,
        address: 0,
      }
      this.$refs.companyValidation.validate().then(success => {
        const { errors } = this.$refs.companyValidation
        Object.keys(errors).forEach(field => {
          if (this.$data.inputFieldMapping.details.includes(field) && errors[field].length > 0) {
            this.$data.tabErrors.details += 1
          }
          if (this.$data.inputFieldMapping.address.includes(field) && errors[field].length > 0) {
            this.$data.tabErrors.address += 1
          }
        })
        if (!success) {
          return
        }
        this.addCompany()
      })
    },
    addCompany() {
      this.$http.post('/v1/companies', this.$data.formData)
        .then(response => {
          this.$router.push(`/companies/${response.data.id}`)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'company added',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          Object.keys(error.response.data.message).forEach(key => {
            const value = JSON.stringify(error.response.data.message[key][0])

            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${key} = ${value}`,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        })
    },
  },
}
</script>
